import {MenuButton, MenuProvider} from '@ariakit/react'
import {useTranslation} from 'react-i18next'
import {Button} from 'src/components/button/Button'
import {
    StyledAssignmentUploadVideoMenu,
    StyledAssignmentUploadVideoMenuGroup,
    StyledAssignmentUploadVideoMenuItem,
    StyledAssignmentUploadVideoMenuGroupLabel
} from './style'
import {ArrowRightIcon, ArrowUpRightIcon, ChevronDownIcon} from 'src/components/icon'
import {Divider} from 'src/components/divider/Divider'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {useAssignmentsCount} from 'src/features/assignments/services/useAssignmentsCount'
import {invalidationSignal} from 'src/helpers/reactQueryTabSync'
import {QUERY_ACTION_KEYS} from 'src/queryClient'
import {Dispatch, SetStateAction} from 'react'
import {Assignment} from 'src/features/assignment/types'
import {errorHandler, openExternalLink} from 'src/helpers/helpers'
import dayjs from 'dayjs'

export const AssignmentUploadVideoDropdown = ({
    assignment,
    setIsLoadingCb,
    setClickedDarylButtonCb,
    showUploadVideoModalCb
}: {
    assignment: Assignment
    setClickedDarylButtonCb: Dispatch<SetStateAction<boolean>>
    setIsLoadingCb: Dispatch<SetStateAction<boolean>>
    showUploadVideoModalCb: () => void
}) => {
    const {t} = useTranslation()
    const {data: assignmentCount, updateShootsCount: updateAssignmentCount} = useAssignmentsCount()

    const onUploadAssets = () => {
        try {
            setIsLoadingCb(true)
            openExternalLink(
                `https://airbnb.orangelogic.com/asset-management/${assignment.task.daryl_directory_id}?Flat=y&WS=AssetManagement`
            )

            //current in page update
            if (assignmentCount) {
                updateAssignmentCount({
                    ...assignmentCount,
                    assignments_current: assignmentCount.assignments_current - 1,
                    ...(assignment.task_status.status == 'pending_assets'
                        ? {pending_assets: assignmentCount?.pending_assets - 1}
                        : {need_more_assets: assignmentCount?.pending_assets - 1})
                })
            }

            //invalidate involved queries
            invalidationSignal(QUERY_ACTION_KEYS.ASSETS_UPLOADED)
            setClickedDarylButtonCb(true)
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoadingCb(false)
        }
    }

    return (
        <MenuProvider>
            <MenuButton
                render={
                    <Button variant="secondary">
                        {t('assignment:descriptions:upload_assets')}
                        <ChevronDownIcon />
                    </Button>
                }
            />
            <StyledAssignmentUploadVideoMenu gutter={8} sameWidth>
                <StyledAssignmentUploadVideoMenuGroup>
                    <StyledAssignmentUploadVideoMenuItem
                        onClick={showUploadVideoModalCb}
                        render={
                            <Flexbox justify="space-between">
                                {t('assignment:upload_edited_video')}
                                <ArrowRightIcon />
                            </Flexbox>
                        }
                    />

                    <StyledAssignmentUploadVideoMenuItem
                        onClick={onUploadAssets}
                        render={
                            <Flexbox justify="space-between">
                                {t('assignment:open_daryl')}
                                <ArrowUpRightIcon />
                            </Flexbox>
                        }
                    />
                </StyledAssignmentUploadVideoMenuGroup>

                {dayjs(assignment.to_shoot_at).isBefore(dayjs()) && (
                    <>
                        <Divider direction="horizontal" />
                        <StyledAssignmentUploadVideoMenuGroup>
                            <StyledAssignmentUploadVideoMenuGroupLabel>
                                {t('assignment:upon_request')}
                            </StyledAssignmentUploadVideoMenuGroupLabel>
                            <StyledAssignmentUploadVideoMenuItem
                                disabled
                                onClick={() => openExternalLink(`https://airbnb.com`)}
                                render={
                                    <Flexbox justify="space-between">
                                        {t('assignment:open_ikonic')}
                                        <ArrowUpRightIcon />
                                    </Flexbox>
                                }
                            />
                        </StyledAssignmentUploadVideoMenuGroup>
                    </>
                )}
            </StyledAssignmentUploadVideoMenu>
        </MenuProvider>
    )
}

import PropTypes from 'prop-types'
import {useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {yupResolver} from '@hookform/resolvers/yup'
import {SelectFreelancer} from './select-freelancer/SelectFreelancer'
import {HostInfos} from './host-infos/HostInfos'
import {Modal} from '../../../../../../components/modal-legacy/Modal'
import {Button} from '../../../../../../components/button/Button'
import {Textarea} from '../../../../../../components/textarea-legacy/Textarea.tsx'
import {InputText} from '../../../../../../components/input-text/InputText'
import {CheckIcon} from '../../../../../../assets/icons/CheckIcon'
import {showAlert} from '../../../../../../store/appGenerics'
import {ALERT_LEVELS, SERVICE_TYPE} from '../../../../../../helpers/constants'
import {useAdminPhotographers} from '../../../../../../hooks'
import {getToOptions, getSendMoneyToFreelancerReasons, getSendMoneyToHostReasons, getActionOptions} from './constants'

import {AdjustmentModalSchema} from './AdjustmentModal.schema'

import {
    StyledTitle,
    FooterWrapper,
    RadioWrapper,
    StyledRadio,
    StyledRadioSection,
    StyledRadioTitle,
    StyledEmail,
    StyledName,
    StyledOptionContainer,
    RadiosWrapper,
    InputSectionWrapper,
    TextAreaWrapper,
    StyledBody,
    StyledBodyWrapper,
    StyledLabelInfo,
    StyledCurrency
} from './style'
import {useUpdateAdjustment} from '../../../../../../hooks'
import {Checkbox} from '../../../../../../components/checkbox-legacy/Checkbox.tsx'

export const AdjustmentModal = ({onClose, quoteData}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {mutate, isLoading} = useUpdateAdjustment({
        selectedQuoteID: quoteData?.id,
        onSuccess: () => {
            dispatch(
                showAlert({
                    message: t('admin:assignments:adjustment_modal:adjustment_confirmed'),
                    level: ALERT_LEVELS.SUCCESS
                })
            )
            onClose()
        }
    })

    const taskId = quoteData?.task?.id
    const defaultHost = quoteData?.host
    const defaultPhotographer = quoteData?.photographer
    const assignmentAmount = quoteData?.assignments?.[0]?.amount
    const isWalkthrough = quoteData?.assignments?.[0]?.task_service?.id === SERVICE_TYPE.WALKTHROUGH

    const {previousPhotographers} = useAdminPhotographers(taskId, defaultPhotographer?.id)
    const toOptions = getToOptions(!isWalkthrough)
    const actionOptions = getActionOptions()

    const {
        handleSubmit,
        register,
        watch,
        control,
        resetField,
        setValue,
        formState: {isValid, errors}
    } = useForm({
        resolver: yupResolver(AdjustmentModalSchema),
        mode: 'all',
        defaultValues: {
            photographer: {
                value: defaultPhotographer?.id,
                label: defaultPhotographer?.name,
                data: defaultPhotographer
            }
        }
    })

    const action = watch('action')
    const to = watch('to')
    const photographer = watch('photographer')
    const reason = watch('reason')
    const customAirbnbId = watch('custom_airbnb_id')

    useEffect(() => {
        resetField('to')
        resetField('photographer')
        resetField('reason')
        resetField('notes')
        resetField('amount')
    }, [action])

    useEffect(() => {
        resetField('photographer')
        resetField('reason')
        resetField('notes')
        resetField('amount')
        resetField('custom_airbnb_id')
        resetField('airbnb_user_id')
    }, [to])

    const onSubmit = formValues => {
        const refactoredFormValues = {
            to: formValues.to,
            action: formValues.action,
            amount: formValues.amount,
            reason: formValues.reason,
            notes: formValues.notes,
            ...(formValues.airbnb_user_id
                ? {airbnb_user_id: formValues.airbnb_user_id}
                : {user_id: to === 'freelancer' ? formValues.photographer?.value : defaultHost?.id})
        }
        mutate(refactoredFormValues)
    }

    const filterOptions = optionsList => {
        return optionsList?.length
            ? optionsList.map(person => ({
                  value: person?.id,
                  label: (
                      <StyledOptionContainer>
                          <StyledLabelInfo>
                              <StyledName>{person?.name}</StyledName>
                              <StyledEmail>{person?.email}</StyledEmail>
                          </StyledLabelInfo>
                          {person?.id === photographer?.value && <CheckIcon />}
                      </StyledOptionContainer>
                  ),
                  data: person
              }))
            : []
    }
    const reasons = to === 'host' ? getSendMoneyToHostReasons() : getSendMoneyToFreelancerReasons(isWalkthrough)

    const onChangeReason = reason => {
        setValue('reason', reason.value)
        setValue('amount', reason.getAmount(assignmentAmount, quoteData?.assignments?.[0]?.travel_compensation))
    }

    return (
        <Modal onClose={onClose} isLoading={isLoading} width="640px">
            <form onSubmit={handleSubmit(onSubmit)}>
                <StyledTitle>{t('admin:assignments:adjustment_modal:adjustments')}</StyledTitle>
                <StyledBody>
                    <StyledRadioSection>
                        <StyledRadioTitle>{t('admin:assignments:adjustment_modal:action')}</StyledRadioTitle>
                        <RadioWrapper>
                            {actionOptions.map(item => (
                                <StyledRadio
                                    value={item.value}
                                    label={item.label}
                                    fillRadioImg
                                    isSmall
                                    {...register('action')}
                                    key={item.value}
                                    width={180}
                                />
                            ))}
                        </RadioWrapper>
                    </StyledRadioSection>
                    {action && (
                        <>
                            <StyledBodyWrapper>
                                <RadiosWrapper>
                                    <StyledRadioTitle>
                                        {action === 'send_money'
                                            ? t('admin:assignments:adjustment_modal:to')
                                            : t('admin:assignments:adjustment_modal:from')}
                                    </StyledRadioTitle>
                                    <RadioWrapper>
                                        {toOptions.map(item => (
                                            <StyledRadio
                                                value={item.value}
                                                label={item.label}
                                                fillRadioImg
                                                isSmall
                                                {...register('to')}
                                                key={item.value}
                                                width={180}
                                            />
                                        ))}
                                    </RadioWrapper>
                                </RadiosWrapper>
                                {to === 'host' && !customAirbnbId && <HostInfos defaultHost={defaultHost} />}
                                {to === 'freelancer' && !customAirbnbId && (
                                    <SelectFreelancer
                                        control={control}
                                        freelancer={photographer}
                                        defaultPhotographer={defaultPhotographer}
                                        previousPhotographers={previousPhotographers}
                                        filterOptions={filterOptions}
                                    />
                                )}

                                {customAirbnbId && to === 'freelancer' && (
                                    <TextAreaWrapper>
                                        <label>{t('commons:airbnb_id')}</label>
                                        <InputText
                                            width="273px"
                                            placeholder="Enter airbnb ID"
                                            {...register('airbnb_user_id')}
                                            errorMessage={t(errors.airbnb_user_id?.message)}
                                            size="sm"
                                        />
                                    </TextAreaWrapper>
                                )}

                                {to === 'freelancer' && (
                                    <Checkbox
                                        name="custom_airbnb_id"
                                        {...register('custom_airbnb_id')}
                                        errors={errors?.no_reminder}
                                        label={'Send with Airbnb ID'}
                                    />
                                )}
                            </StyledBodyWrapper>
                            {to && action === 'send_money' && (
                                <InputSectionWrapper>
                                    <RadiosWrapper>
                                        <StyledRadioTitle>{t('admin:assignment_cancellation:reason')}</StyledRadioTitle>
                                        <RadioWrapper $isGrid={to === 'freelancer'}>
                                            {reasons.map(item => (
                                                <StyledRadio
                                                    value={item.value}
                                                    label={item.label}
                                                    fillRadioImg
                                                    isSmall
                                                    {...register('reason')}
                                                    key={item.value}
                                                    onChange={() => onChangeReason(item)}
                                                />
                                            ))}
                                        </RadioWrapper>
                                    </RadiosWrapper>
                                </InputSectionWrapper>
                            )}
                            {to && (
                                <>
                                    <InputSectionWrapper>
                                        <TextAreaWrapper>
                                            <StyledRadioTitle>{t('commons:amount')}</StyledRadioTitle>
                                            <InputText
                                                {...register('amount')}
                                                placeholder={t('admin:assignments:adjustment_modal:value')}
                                                width="160px"
                                                errorMessage={errors.amount?.message}
                                                typeIcon={<StyledCurrency>{'USD'}</StyledCurrency>}
                                                size="sm"
                                            />
                                        </TextAreaWrapper>
                                    </InputSectionWrapper>
                                    <InputSectionWrapper>
                                        <TextAreaWrapper>
                                            <label>{t('commons:notes')}</label>
                                            <Textarea
                                                placeholder={
                                                    reason === 'other'
                                                        ? t('admin:assignment_cancellation:write_here_reason')
                                                        : t('admin:assignments:notes_placeholder')
                                                }
                                                {...register('notes')}
                                            />
                                        </TextAreaWrapper>
                                    </InputSectionWrapper>
                                </>
                            )}
                        </>
                    )}
                </StyledBody>
                <FooterWrapper>
                    <Button onClick={onClose} variant="tertiary" disabled={isLoading}>
                        {t('commons:cancel')}
                    </Button>
                    <Button type="submit" disabled={!isValid || isLoading}>
                        {t('commons:confirm')}
                    </Button>
                </FooterWrapper>
            </form>
        </Modal>
    )
}

AdjustmentModal.propTypes = {
    onClose: PropTypes.func,
    quoteData: PropTypes.object
}

import i18next from 'i18next'
import {AirbnbIcon} from '../assets/icons/AirbnbIcon'
import {MapPinIcon} from '../assets/icons/MapPinIcon'
import {ClockIcon} from '../assets/icons/ClockIcon'
import {PriceIcon} from '../assets/icons/PriceIcon'
import {CalendarIcon} from '../assets/icons/CalendarIcon'
import {UserIcon} from '../assets/icons/UserIcon'
import {StatusIcon} from '../assets/icons/StatusIcon'

import {TABLE_ALIGNMENTS} from './constants'

export const getNewAssignmentsColumns = () =>
    [
        {
            name: 'external_id',
            label: i18next.t('commons:airbnb_id'),
            icon: <AirbnbIcon />,
            width: '19%',
            sortable: true
        },

        {
            name: 'location',
            label: i18next.t('dashboard:new_assignments:area'),
            icon: <MapPinIcon />,
            width: '13%',
            sortable: true
        },

        {
            name: 'expiration_date',
            label: i18next.t('dashboard:new_assignments:expires'),
            icon: <ClockIcon />,
            width: '12%',
            sortable: true
        },
        {
            name: 'host_availability_dates',
            label: i18next.t('dashboard:new_assignments:desired_shoot_dates'),
            icon: <CalendarIcon />,
            width: '20%',
            sortable: true
        },
        {
            name: 'amount',
            label: i18next.t('dashboard:new_assignments:amount'),
            icon: <PriceIcon />,
            width: '9%',
            sortable: true
        },
        {
            name: 'actions',
            width: '27%',
            alignment: TABLE_ALIGNMENTS.RIGHT,
            mobileFullLength: true
        }
    ] as const

export const getCompletedShootsColumns = () =>
    [
        {
            name: 'external_id',
            label: i18next.t('commons:listing'),
            icon: <AirbnbIcon />,
            width: '15%',
            sortable: true
        },

        {
            name: 'host',
            label: i18next.t('dashboard:calendar:table:host'),
            icon: <UserIcon />,
            width: '15%',
            sortable: true
        },
        {
            name: 'completed_at',
            label: i18next.t('commons:completed_at'),
            icon: <CalendarIcon />,
            width: '15%',
            sortable: true
        },
        {
            name: 'amount',
            label: i18next.t('dashboard:new_assignments:amount'),
            icon: <PriceIcon />,
            width: '10%',
            sortable: true
        },
        {
            name: 'status',
            label: i18next.t('dashboard:calendar:table:status'),
            icon: <StatusIcon />,
            width: '15%'
        },
        {
            name: 'daryl_cta',
            width: '10%',
            mobileFullLength: true,
            alignment: TABLE_ALIGNMENTS.RIGHT
        },
        {
            name: 'ikonic_cta',
            width: '10%',
            mobileFullLength: true,
            alignment: TABLE_ALIGNMENTS.RIGHT
        },
        {
            name: 'transactions',
            width: '10%',
            mobileFullLength: true,
            alignment: TABLE_ALIGNMENTS.RIGHT
        }
    ] as const

export const getActiveShootsColumns = () =>
    [
        {
            name: 'external_id',
            label: i18next.t('commons:listing'),
            icon: <AirbnbIcon />,
            width: '20%',
            sortable: true
        },

        {
            name: 'host',
            label: i18next.t('dashboard:calendar:table:host'),
            icon: <UserIcon />,
            width: '17%',
            sortable: true
        },
        {
            name: 'location',
            label: i18next.t('dashboard:new_assignments:area'),
            icon: <MapPinIcon />,
            width: '17%',
            sortable: true
        },
        {
            name: 'to_shoot_at',
            label: i18next.t('commons:assignment_date'),
            icon: <CalendarIcon />,
            width: '17%',
            sortable: true
        },
        {
            name: 'status_name',
            label: i18next.t('dashboard:calendar:table:status'),
            icon: <StatusIcon />,
            width: '17%',
            sortable: true
        },
        {
            name: 'actions',
            width: '12%',
            alignment: TABLE_ALIGNMENTS.RIGHT,
            mobileFullLength: true
        }
    ] as const

export const getPendingShootDateColumns = () =>
    [
        {
            name: 'external_id',
            label: i18next.t('commons:listing'),
            icon: <AirbnbIcon />,
            width: '20%',
            sortable: true
        },
        {
            name: 'location',
            label: i18next.t('dashboard:new_assignments:area'),
            icon: <MapPinIcon />,
            width: '16%',
            sortable: true
        },
        {
            name: 'host',
            label: i18next.t('dashboard:calendar:table:host'),
            icon: <UserIcon />,
            width: '15%',
            sortable: true
        },
        {
            name: 'host_availability_dates',
            label: i18next.t('dashboard:new_assignments:desired_shoot_dates'),
            icon: <CalendarIcon />,
            width: '23%',
            sortable: true
        },
        {
            name: 'status',
            label: i18next.t('dashboard:calendar:table:status'),
            icon: <StatusIcon />,
            width: '20%'
        },
        {
            name: 'actions',
            width: '6%',
            alignment: TABLE_ALIGNMENTS.RIGHT,
            mobileFullLength: true
        }
    ] as const

export const getPendingAssetsColumns = () =>
    [
        {
            name: 'external_id',
            label: i18next.t('commons:listing'),
            icon: <AirbnbIcon />,
            width: '20%',
            sortable: true
        },
        {
            name: 'location',
            label: i18next.t('dashboard:new_assignments:area'),
            icon: <MapPinIcon />,
            width: '17%',
            sortable: true
        },
        {
            name: 'host',
            label: i18next.t('dashboard:calendar:table:host'),
            icon: <UserIcon />,
            width: '17%',
            sortable: true
        },
        {
            name: 'to_shoot_at',
            label: i18next.t('commons:assignment_date'),
            icon: <CalendarIcon />,
            width: '17%',
            sortable: true
        },
        {
            name: 'status',
            label: i18next.t('dashboard:calendar:table:status'),
            icon: <StatusIcon />,
            width: '17%'
        },
        {
            name: 'actions',
            width: '12%',
            alignment: TABLE_ALIGNMENTS.RIGHT,
            mobileFullLength: true
        }
    ] as const

export const getNeedMoreAssetsColumns = () =>
    [
        {
            name: 'external_id',
            label: i18next.t('commons:listing'),
            icon: <AirbnbIcon />,
            width: '20%',
            sortable: true
        },
        {
            name: 'location',
            label: i18next.t('dashboard:new_assignments:area'),
            icon: <MapPinIcon />,
            width: '17%',
            sortable: true
        },
        {
            name: 'host',
            label: i18next.t('dashboard:calendar:table:host'),
            icon: <UserIcon />,
            width: '17%',
            sortable: true
        },
        {
            name: 'to_shoot_at',
            label: i18next.t('commons:assignment_date'),
            icon: <CalendarIcon />,
            width: '17%',
            sortable: true
        },
        {
            name: 'status',
            label: i18next.t('dashboard:calendar:table:status'),
            icon: <StatusIcon />,
            width: '20%'
        },
        {
            name: 'actions',
            width: '9%',
            alignment: TABLE_ALIGNMENTS.RIGHT,
            mobileFullLength: true
        }
    ] as const

import * as Yup from 'yup'
import i18n from '../../../../../../translations/i18n'

export const AdjustmentModalSchema = Yup.object().shape({
    action: Yup.string().required(),
    to: Yup.string().required(),
    photographer: Yup.object().when(['to'], {
        is: fromTo => fromTo === 'freelancer',
        then: Yup.object().required().nullable()
    }),
    reason: Yup.string().when('action', {
        is: 'send_money',
        then: Yup.string().required()
    }),
    amount: Yup.number()
        .positive()
        .typeError(i18n.t('errors:number_invalid'))
        .required()
        .test('maxDigitsAfterDecimal', i18n.t('errors:number_invalid'), amount =>
            /^[0-9]+(?:\.[0-9]{1,2})?$/.test(amount)
        ),
    custom_airbnb_id: Yup.bool(),
    airbnb_user_id: Yup.string().when('custom_airbnb_id', {
        is: custom_airbnb_id => custom_airbnb_id,
        then: Yup.string().required('errors:required')
    }),
    notes: Yup.string().required()
})

import i18next from 'i18next'

export const getActionOptions = () => [
    {
        value: 'send_money',
        label: i18next.t('admin:assignments:adjustment_modal:send_money')
    },
    {
        value: 'recover_money',
        label: i18next.t('admin:assignments:adjustment_modal:recover_money')
    }
]

export const getToOptions = showHostOptions => [
    ...(showHostOptions
        ? [
              {
                  value: 'host',
                  label: i18next.t('commons:host')
              }
          ]
        : []),
    {
        value: 'freelancer',
        label: i18next.t('admin:freelancer')
    }
]

export const getSendMoneyToHostReasons = () => [
    {
        value: 'quality_complain_25',
        label: i18next.t('admin:assignments:adjustment_modal:quality_complain_value', {value: 25}),
        getAmount: amount => amount / 4
    },
    {
        value: 'process_complain_10',
        label: i18next.t('admin:assignments:adjustment_modal:process_complain_value', {value: 10}),
        getAmount: amount => amount / 10
    },
    {
        value: 'other',
        label: i18next.t('admin:assignments:adjustment_modal:other'),
        getAmount: () => null
    }
]

export const getSendMoneyToFreelancerReasons = isWalkthrough =>
    isWalkthrough
        ? [
              {
                  value: 'last_minute_cancellation',
                  label: i18next.t('admin:assignments:adjustment_modal:last_minute_cancellation'),
                  getAmount: (walkthroughRate, travelCompensation) => walkthroughRate / 2 + travelCompensation
              },
              {
                  value: 'host_no_show',
                  label: i18next.t('admin:assignments:adjustment_modal:host_no_show'),
                  getAmount: (walkthroughRate, travelCompensation) => walkthroughRate + travelCompensation
              },
              {
                  value: 'travel_lodging',
                  label: i18next.t('admin:assignments:adjustment_modal:travel_lodging'),
                  getAmount: () => null
              },
              {
                  value: 'travel_transport',
                  label: i18next.t('admin:assignments:adjustment_modal:travel_transport'),
                  getAmount: () => null
              },
              {
                  value: 'travel_per_diem',
                  label: i18next.t('admin:assignments:adjustment_modal:travel_per_diem'),
                  getAmount: () => null
              },
              {
                  value: 'travel_travel_time',
                  label: i18next.t('admin:assignments:adjustment_modal:travel_travel_time'),
                  getAmount: () => null
              },
              {
                  value: 'wire_fee',
                  label: i18next.t('admin:assignments:adjustment_modal:wire_fee'),
                  getAmount: () => null
              },
              {
                  value: 'other',
                  label: i18next.t('admin:assignments:adjustment_modal:other'),
                  getAmount: () => null
              }
          ]
        : [
              {
                  value: 'process_complain_10',
                  label: i18next.t('admin:assignments:adjustment_modal:process_complain_value', {value: 10}),
                  getAmount: amount => amount / 10
              },
              {
                  value: 'host_no_show_50',
                  label: i18next.t('admin:assignments:adjustment_modal:host_no_show_value', {value: 50}),
                  getAmount: amount => amount / 2
              },
              {
                  value: 'host_cancellation_50',
                  label: i18next.t('admin:assignments:adjustment_modal:host_cancellation_value', {value: 50}),
                  getAmount: amount => amount / 2
              },
              {
                  value: 'bedroom_adjustment',
                  label: i18next.t('admin:assignments:adjustment_modal:bedroom_adjustment'),
                  getAmount: () => null
              },
              {
                  value: 'other',
                  label: i18next.t('admin:assignments:adjustment_modal:other'),
                  getAmount: () => null
              }
          ]

import {useTranslation} from 'react-i18next'
import {AssignmentCard} from 'src/components/assignments/assignment-card/AssignmentCard.tsx'
import {Table} from 'src/components/table/Table.tsx'
import {FC, useState} from 'react'
import {
    StyledAdminName,
    StyledEmptyState,
    StyledStatusBadge,
    StyledTruncatedText
} from 'src/pages/admin/assignments/assignment/forced-matches/style.ts'
import {Tooltip} from 'src/components/tooltip/Tooltip.tsx'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {useAdmins} from 'src/features/admin/services/useAdmins.ts'
import {Skeleton} from 'src/components/skeleton/Skeleton.tsx'
import {formatLocaleDate} from 'src/dayjs.ts'
import {Button} from 'src/components/button/Button.tsx'
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalOverlay,
    ModalParagraph,
    ModalTitle
} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {useUpdateForcedMatch} from 'src/features/admin/services/useUpdateForcedMatch.ts'
import {Spinner} from 'src/components/spinner/Spinner.tsx'

interface ForcedMatch {
    admin_name: string
    created_at: string
    created_by_admin_id: number
    id: number
    ignored_markets: boolean
    ignored_previous_rejections: boolean
    notes: string | null
    photographer_name: string
    photographer_rate: number
    quoteId: number
    status: 'active' | 'inactive'
}

export const ForcedMatches: FC<{forcedMatches?: ForcedMatch[]; quoteId: number}> = ({forcedMatches = [], quoteId}) => {
    const {t} = useTranslation()
    const [forcedMatchIdToDeactivate, setForcedMatchIdToDeactivate] = useState<number | null>(null)
    const adminsQuery = useAdmins()
    const updateForcedMatchMutation = useUpdateForcedMatch({
        quoteId,
        onSuccess: () => setForcedMatchIdToDeactivate(null)
    })

    return (
        <>
            <AssignmentCard
                title={<h3>{t('admin:assignments:forced_matches:title')}</h3>}
                showUpdatedAt={false}
                table={
                    <Table
                        variant="neutral"
                        style={{height: 360}}
                        data={forcedMatches}
                        emptyStateComponent={
                            <StyledEmptyState>{t('admin:assignments:forced_matches:empty_state')}</StyledEmptyState>
                        }
                        isLoading={false}
                        columns={[
                            {
                                cellRender: forcedMatch => {
                                    const admin = adminsQuery.data?.find(
                                        admin => admin.id == forcedMatch.created_by_admin_id
                                    )
                                    return (
                                        <Flexbox direction="column" gap={0.5}>
                                            {admin ? (
                                                <Tooltip
                                                    content={`${admin.first_name} ${admin.last_name}`}
                                                    triggerProps={{style: {width: '100%'}}}
                                                >
                                                    <StyledAdminName>
                                                        {`${admin.first_name} ${admin.last_name}`}
                                                    </StyledAdminName>
                                                </Tooltip>
                                            ) : (
                                                <Skeleton width="100%" height={20} />
                                            )}
                                            <Tooltip
                                                content={formatLocaleDate(forcedMatch.created_at, 'lll')}
                                                triggerProps={{style: {width: '100%'}}}
                                            >
                                                <StyledTruncatedText>
                                                    {formatLocaleDate(forcedMatch.created_at, 'lll')}
                                                </StyledTruncatedText>
                                            </Tooltip>
                                        </Flexbox>
                                    )
                                },
                                label: t('admin:assignments:forced_matches:created_by'),
                                width: '12%'
                            },
                            {
                                cellRender: forcedMatch =>
                                    forcedMatch.photographer_name ? (
                                        <Tooltip
                                            content={forcedMatch.photographer_name}
                                            triggerProps={{style: {width: '100%'}}}
                                        >
                                            <StyledAdminName>{forcedMatch.photographer_name}</StyledAdminName>
                                        </Tooltip>
                                    ) : (
                                        '-'
                                    ),
                                label: t('admin:freelancer'),
                                width: '12%'
                            },
                            {
                                cellRender: forcedMatch =>
                                    forcedMatch.ignored_previous_rejections ? t('commons:yes') : t('commons:no'),
                                label: t('admin:assignments:forced_matches:ignore_prev_rejections'),
                                width: '12%'
                            },
                            {
                                cellRender: forcedMatch =>
                                    forcedMatch.ignored_markets ? t('commons:yes') : t('commons:no'),
                                label: t('admin:assignments:forced_matches:ignore_freel_markets'),
                                width: '12%'
                            },
                            {
                                cellRender: forcedMatch =>
                                    forcedMatch.photographer_rate ? `${forcedMatch.photographer_rate}$` : '-',
                                label: t('admin:assignments:forced_matches:manual_rate'),
                                width: '12%'
                            },
                            {
                                cellRender: forcedMatch =>
                                    forcedMatch.notes ? (
                                        <Tooltip content={forcedMatch.notes}>
                                            <StyledTruncatedText style={{textDecoration: 'underline'}}>
                                                {t('admin:assignments:forced_matches:view_notes')}
                                            </StyledTruncatedText>
                                        </Tooltip>
                                    ) : (
                                        '-'
                                    ),
                                label: t('commons:notes'),
                                width: '12%'
                            },
                            {
                                cellRender: forcedMatch => (
                                    <StyledStatusBadge>
                                        {t(`admin:assignments:forced_matches:${forcedMatch.status}`)}
                                    </StyledStatusBadge>
                                ),
                                label: t('commons:status'),
                                width: '12%'
                            },
                            {
                                cellRender: (forcedMatch, itemIndex) => (
                                    <>
                                        {itemIndex == 0 && forcedMatch.status == 'active' && (
                                            <Button
                                                variant="tertiary"
                                                onClick={() => setForcedMatchIdToDeactivate(forcedMatch.id)}
                                            >
                                                {t('admin:assignments:forced_matches:deactivate')}
                                            </Button>
                                        )}
                                    </>
                                ),
                                width: '16%'
                            }
                        ]}
                    />
                }
            />
            {forcedMatchIdToDeactivate != null && (
                <>
                    <ModalOverlay onClick={() => setForcedMatchIdToDeactivate(null)} />
                    <Modal width="432px">
                        <ModalBody>
                            <ModalTitle>
                                {t('admin:assignments:forced_matches:deactivate_confirmation_title')}
                            </ModalTitle>
                            <ModalParagraph>
                                {t('admin:assignments:forced_matches:deactivate_confirmation_paragraph')}
                            </ModalParagraph>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant="tertiary"
                                onClick={() => setForcedMatchIdToDeactivate(null)}
                                disabled={updateForcedMatchMutation.isLoading}
                            >
                                {t('commons:dismiss')}
                            </Button>
                            <Button
                                onClick={() =>
                                    updateForcedMatchMutation.mutate({
                                        forcedMatchId: forcedMatchIdToDeactivate,
                                        payload: {status: 'inactive'}
                                    })
                                }
                                disabled={updateForcedMatchMutation.isLoading}
                            >
                                {t('commons:confirm')}
                                {updateForcedMatchMutation.isLoading && <Spinner />}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>
            )}
        </>
    )
}

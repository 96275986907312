import {
    AssignmentDescriptionParagraph,
    AssignmentDescriptionText,
    AssignmentDescriptionTitle,
    AssignmentDescriptionWrapper
} from 'src/features/assignment/components/assignment-description-atoms/AssignmentDescriptionAtoms.tsx'
import {useTranslation} from 'react-i18next'
import {Assignment, ServiceType} from 'src/features/assignment/types.ts'
import {Button} from 'src/components/button/Button'
import {ArrowUpRightIcon} from 'src/components/icon'
import {openExternalLink} from 'src/helpers/helpers'

export const PendingPaymentDescription = ({
    assignment,
    serviceType
}: {
    assignment: Assignment
    serviceType: ServiceType
}) => {
    const {t} = useTranslation()

    return (
        <AssignmentDescriptionWrapper>
            <AssignmentDescriptionText>
                <AssignmentDescriptionTitle>
                    {t(`assignment:descriptions:assets_approved:title:${serviceType}`)}
                </AssignmentDescriptionTitle>
                <AssignmentDescriptionParagraph>
                    {t(`assignment:descriptions:assets_approved:paragraph:${serviceType}`)}
                </AssignmentDescriptionParagraph>
            </AssignmentDescriptionText>

            {assignment.task_category.name == 'Experiences' && assignment.task_media.name == 'video' && (
                <Button disabled variant="secondary" onClick={() => openExternalLink(`https://airbnb.com`)}>
                    <span>{t('assignment:open_ikonic')}</span>
                    <ArrowUpRightIcon />
                </Button>
            )}
        </AssignmentDescriptionWrapper>
    )
}

import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {useSearchParams} from 'react-router-dom'

import {AssignmentCard} from '../../../../../components/assignments/assignment-card/AssignmentCard'
import {Chip} from '../../../../../components/chip/Chip'
import {Button} from '../../../../../components/button/Button'
import {ForceMatchModal} from './force-match-modal/ForceMatchModal'

import {useAdminQuoteLogs} from '../../../../../hooks/queries'
import {QUOTE_LOGS_STATUSES, QUOTE_STATUSES} from '../../../../../helpers/constants'
import {formatCurrency, formatLocaleDate, getMetaAttribute, renderTableQuoteStatus} from '../../../../../helpers/utils'

import {StyledAssignmentCardDetailsItem} from '../style'
import {ButtonLink} from '../../../../../components/button-link/ButtonLink'

export const QuoteCard = ({quoteData}) => {
    const {t} = useTranslation()
    const [searchParams] = useSearchParams()
    const quoteLogsQuery = useAdminQuoteLogs(searchParams.get('quoteId'), QUOTE_LOGS_STATUSES.QUOTE)

    const [showForceMatchModal, setShowForceMatchModal] = useState(false)

    return (
        <>
            {showForceMatchModal && (
                <ForceMatchModal onClose={() => setShowForceMatchModal(false)} quoteData={quoteData} />
            )}
            <AssignmentCard
                title={
                    <>
                        <h3>{t('admin:assignments:quote:title')}</h3>
                        <Chip
                            text={t(renderTableQuoteStatus(quoteData?.quote_status?.status).label)}
                            rounded
                            size="medium"
                        />
                    </>
                }
                statusUpdatedAt={formatLocaleDate(quoteLogsQuery.remappedData[0]?.updated_at, 'LLL')}
                actions={
                    <>
                        {quoteData?.host_portal_link &&
                            quoteData?.quote_status.status !== QUOTE_STATUSES.MARKET_CLOSED && (
                                <ButtonLink to={quoteData.host_portal_link} variant="tertiaryPrimary" target="_blank">
                                    {t('admin:assignments:quote:host_portal_button')}
                                </ButtonLink>
                            )}
                        {quoteData?.quote_status?.status === QUOTE_STATUSES.SENT && (
                            <Button onClick={() => setShowForceMatchModal(true)} variant="tertiaryPrimary">
                                {t('admin:assignments:quote:force_match_button')}
                            </Button>
                        )}
                    </>
                }
                details={
                    <>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:quote:price')}</span>
                            <p>
                                {quoteData?.amount
                                    ? formatCurrency(quoteData?.amount, 'USD')
                                    : t('single_shooting:free')}
                            </p>
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:quote:bedrooms')}</span>
                            <p>{getMetaAttribute(quoteData?.entity?.meta, 'Bedrooms') || '-'}</p>
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('commons:project')}</span>
                            <p>{quoteData?.task_category?.name}</p>
                        </StyledAssignmentCardDetailsItem>

                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:quote:sent_date')}</span>
                            <p>{formatLocaleDate(quoteData?.created_at, 'YYYY-MM-DD')}</p>
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:quote:expiration_date')}</span>
                            <p>{formatLocaleDate(quoteData?.expire_at, 'YYYY-MM-DD')}</p>
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:quote:signed_date')}</span>
                            <p>{formatLocaleDate(quoteData?.accepted_at, 'YYYY-MM-DD')}</p>
                        </StyledAssignmentCardDetailsItem>

                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:quote:declined_date')}</span>
                            <p>{formatLocaleDate(quoteData?.declined_at, 'YYYY-MM-DD')}</p>
                        </StyledAssignmentCardDetailsItem>
                        <StyledAssignmentCardDetailsItem>
                            <span>{t('admin:assignments:quote:declined_reason')}</span>
                            <p>
                                {quoteData?.declined_reason
                                    ? t(`single_shooting:${quoteData.declined_reason.replace('.', ':decline:')}`)
                                    : '-'}
                            </p>
                        </StyledAssignmentCardDetailsItem>
                    </>
                }
            />
        </>
    )
}

QuoteCard.propTypes = {
    quoteData: PropTypes.object
}
